import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPayoffs, resetPayoffs } from '../features/payoffs/payoffsSlice'
import PayoffChart from "../components/PayoffChart"

function PayoffsPage() {
  const {payoffsData} = useSelector((state) => state.payoffs)
  const [payoffType, setPayoffType] = useState('call')
  const [payoffDirection, setPayoffDirection] = useState('long')
  const [showValue, setShowValue] = useState('True')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPayoffs({
      payoff_type: payoffType, 
      payoff_direction: payoffDirection, 
      show_value: showValue
    })).unwrap();
    dispatch(resetPayoffs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const payoffChange = (e) => {
    setPayoffType(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: e.target.value, 
        payoff_direction: payoffDirection, 
        show_value: showValue
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }
  
  const directionChange = (e) => {
    setPayoffDirection(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: payoffType, 
        payoff_direction: e.target.value, 
        show_value: showValue
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }
  
  const showValueChange = (e) => {
    setShowValue(e.target.value, (
      dispatch(getPayoffs({
        payoff_type: payoffType, 
        payoff_direction: payoffDirection, 
        show_value: e.target.value
      })).unwrap()
    ));
    dispatch(resetPayoffs)
  }

  if (payoffsData === undefined | []) {
    return <div></div> 
  }

  return (
    <>
      <section className="payoffs">
        <header className="payoffs-header">
          <h2>Payoff Diagrams</h2>
        </header>
        <div className="payoffs-data">
          <div className="payoffs-inputs">
            <form>
              <div className="radio-group">
                <label className="field-labels">Payoff Type</label>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="call" 
                    id='type-call'
                    name="payoff-type" 
                    checked={payoffType === 'call'}
                    onChange={payoffChange}
                  /> 
                  <label 
                    htmlFor='type-call'
                  >
                    Call
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="put" 
                    id='type-put' 
                    name="payoff-type"
                    checked={payoffType === 'put'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-put'
                  >
                    Put
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="stock" 
                    id='type-stock' 
                    name="payoff-type"
                    checked={payoffType === 'stock'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-stock'
                  >
                    Stock
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="forward" 
                    id='type-forward' 
                    name="payoff-type"
                    checked={payoffType === 'forward'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-forward'
                  >
                    Forward
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="collar" 
                    id='type-collar' 
                    name="payoff-type"
                    checked={payoffType === 'collar'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-collar'
                  >
                    Collar
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="spread" 
                    id='type-spread' 
                    name="payoff-type"
                    checked={payoffType === 'spread'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-spread'
                  >
                    Spread
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="backspread" 
                    id='type-backspread' 
                    name="payoff-type"
                    checked={payoffType === 'backspread'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-backspread'
                  >
                    Backspread
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="ratio vertical spread" 
                    id='type-ratio-vertical-spread' 
                    name="payoff-type"
                    checked={payoffType === 'ratio vertical spread'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-ratio-vertical-spread'
                  >
                    Ratio Vertical Spread
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="straddle" 
                    id='type-straddle' 
                    name="payoff-type"
                    checked={payoffType === 'straddle'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-straddle'
                  >
                    Straddle
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="strangle" 
                    id='type-strangle' 
                    name="payoff-type"
                    checked={payoffType === 'strangle'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-strangle'
                  >
                    Strangle
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="butterfly" 
                    id='type-butterfly' 
                    name="payoff-type"
                    checked={payoffType === 'butterfly'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-butterfly'
                  >
                    Butterfly
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="christmas tree" 
                    id='type-christmas-tree' 
                    name="payoff-type"
                    checked={payoffType === 'christmas tree'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-christmas-tree'
                  >
                    Christmas Tree
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="condor" 
                    id='type-condor' 
                    name="payoff-type"
                    checked={payoffType === 'condor'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-condor'
                  >
                    Condor
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="iron butterfly" 
                    id='type-iron-butterfly' 
                    name="payoff-type"
                    checked={payoffType === 'iron butterfly'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-iron-butterfly'
                  >
                    Iron Butterfly
                  </label>
                </div>
                <div className="radiowrapper">
                  <input 
                    type="radio" 
                    value="iron condor" 
                    id='type-iron-condor' 
                    name="payoff-type"
                    checked={payoffType === 'iron condor'}
                    onChange={payoffChange}
                  />
                  <label
                    htmlFor='type-iron-condor'
                  >
                    Iron Condor
                  </label>
                </div>
              </div>
              <div className="radio-group">
                <label className="field-labels">Direction</label>  
                <div id="direction-radio">
                  <div className="radiowrapper">
                    <input 
                      type="radio" 
                      value="long" 
                      id="payoff-direction-long"
                      name="payoff-direction" 
                      checked={payoffDirection === 'long'}
                      onChange={directionChange}
                    /> 
                    <label 
                      htmlFor='payoff-direction-long'
                    >
                      Long
                    </label>
                  </div>
                  <div className="radiowrapper">  
                    <input 
                      type="radio" 
                      value="short"
                      id="payoff-direction-short"
                      name="payoff-direction" 
                      checked={payoffDirection === 'short'}
                      onChange={directionChange}
                    />
                    <label 
                      htmlFor='payoff-direction-short'
                    >
                      Short
                    </label>
                  </div>
                </div>
              </div>
              <div className="radio-group">
                <label className="field-labels">Show Value</label>   
                <div className="value-radio">
                  <div className="radiowrapper">
                    <input 
                      type="radio" 
                      name="show-value" 
                      value="True" 
                      id='value-true'
                      checked={showValue === 'True'}
                      onChange={showValueChange}
                    /> 
                    <label 
                      htmlFor='value-true'
                    >
                      On
                    </label>
                  </div>
                  <div className="radiowrapper">  
                    <input 
                      type="radio" 
                      name="show-value" 
                      value="False" 
                      id='value-false'
                      checked={showValue === 'False'}
                      onChange={showValueChange}
                    /> 
                    <label 
                      htmlFor='value-false'
                    >
                      Off
                    </label>
                  </div>
                </div>
              </div>            
            </form>
          </div>
          <div className="payoffs-graph">
            <div className="payoffs-graph-box">
              { 
                !payoffsData.payoff_dict && 
                <div></div>
              }
              { payoffsData.payoff_dict && <PayoffChart payoffDict={payoffsData.payoff_dict} /> }
            </div>
          </div>
        </div>
      </section>
    </>
  )  
}

export default PayoffsPage